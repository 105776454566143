import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: {
        'Sign Up': 'Sign Up',
        'Password mismatch': 'Password mismatch',
        Username: 'Username',
        'E-Mail': 'E-Mail',
        Password: 'Password',
        'Password Repeat': 'Pasword Repeat',
        Login: 'Login',
        Subscriptions: 'Subscriptions',
        'Subscriptions not found!': 'Subscriptions not found!',
        'Shipstation Update': 'Shipment',
        Logout: 'Logout',
        Customer: 'Members',
        'Customers not found!': 'Customers not found!',
        Product: 'Product',
        Scheduler: 'Scheduler',
        'Check System': 'Transaction Validation',
        Reports: 'Reports',
        'Reports Maintenance': 'Reports Maintenance',
        'Reports not found!': 'Reports not found!',
        'ESP Funnel Mapping': 'ESP Funnel Mapping',
        Bucket: 'Bucket',
        Backup: 'Email Backup',
        'Email Block': 'Email Block',
        'Failed Transactions': 'Failed Transactions',
        'Cancel Memberships': 'Cancel Memberships',
        Contact: 'Contact',
      },
    },
    tr: {
      translations: {
        'Sign Up': 'Kayıt Ol ',
        'Password mismatch': 'Aynı şifreyi giriniz',
        Username: 'Kullanıcı Adı',
        'E-Mail': 'Mail Adresi',
        Password: 'Şifre',
        'Password Repeat': 'Şifre Tekrarla',
        Login: 'Sisteme Giriş',
        Subscriptions: 'Aboneler',
        'Subscriptions not found!': 'Abone kaydı bulunamadı!',
        'Shipstation Update': 'Shipstation Güncelleme',
        Logout: 'Çıkış',
        Customer: 'Customer',
        'Customers not found!': 'Müşteri Bulunamadı!',
        Product: 'Ürünler',
        Scheduler: 'Planlayıcı',
        'Check System': 'Sistem Kontrol',
        Reports: 'Raporlar',
        'Reports Maintenance': 'Rapor Bakımı',
        'Reports not found!': 'Rapor Kayıdı Bulunamadı!',
        'ESP Funnel Mapping': 'ESP-Funnel Güncelleme',
        Bucket: 'Bucket',
        Backup: 'Email Backup',
        'Email Block': 'Email Block',
        'Failed Transactions': 'Failed Transactions',
        'Cancel Memberships': 'Cancel Memberships',
        Contact: 'Contact',
      },
    },
  },
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
})

export default i18n
