/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {EVENT_RESCHEDULE} from '../constant/Operations'
import Select from 'react-select'
import sendlane from '../assets/ic_sendlane.png'
import klaviyo from '../assets/ic_klaviyo.png'
import campaigner from '../assets/ic_campaigner.jpg'
import earnware from '../assets/ic_earnware.png'
import active_campaign from '../assets/ic_active_campaign.png'
import maropost from '../assets/ic_maropost.png'
import postscript from '../assets/ic_postscript.svg'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CCollapse,
  CDataTable,
  CForm,
  CFormGroup,
  CInput,
  CInputFile,
  CLabel,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSelect,
  CSwitch,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react'
import {
  bucketJobOperation,
  deleteBucketsByBucketNo,
  findActiveCampaignListData,
  findBucketSettings,
  findCampaignerListData,
  findDomains,
  findKlaviyoListData,
  findMaropostListData,
  findSettingsByType,
  updateSettings,
  uploadBuckets,
} from '../api/apiCalls'
import Spinner from '../components/Spinner'
import ImportFileAlert from '../components/ImportFileAlert'

toast.configure()
const BucketPage = () => {
  const [notFound, setNotFound] = useState(false)
  const [settingsDetails, setSettingsDetails] = useState([])
  const [smsSettingsDetails, setSmsSettingsDetails] = useState([])
  const [settingsTableData, setSettingsTableData] = useState([])
  const [smsSettingsTableData, setSmsSettingsTableData] = useState([])
  const [webhookSettingsTableData, setWebhookSettingsTableData] = useState([])
  const [webhookDomainsSettingsTableData, setWebhookDomainsSettingsTableData] = useState([])
  const [maropostWebhookSettingsTableData, setMaropostWebhookSettingsTableData] = useState([])
  const [klaviyoWebhookSettingsTableData, setKlaviyoWebhookSettingsTableData] = useState([])
  const [campaignerWebhookSettingsTableData, setCampaignerWebhookSettingsTableData] = useState([])
  const [postscriptWebhookSettingsTableData, setPostscriptWebhookSettingsTableData] = useState([])
  const [skippedEmailDomainSettingsData, setSkippedEmailDomainSettingsData] = useState([])
  const [domainData, setDomainData] = useState([])
  const [activeCampaignListData, setActiveCampaignListData] = useState([])
  const [maropostListData, setMaropostListData] = useState([])
  const [maropostAccountListData, setMaropostAccountListData] = useState([])
  const [klaviyoListData, setKlaviyoListData] = useState([])
  const [klaviyoAccountListData, setKlaviyoAccountListData] = useState([])
  const [campaignerListData, setCampaignerListData] = useState([])
  const [campaignerAccountListData, setCampaignerAccountListData] = useState([])
  const [postscriptAccountListData, setPostscriptAccountListData] = useState([])
  const [selected, setSelected] = useState({})
  const [smsSelected, setSmsSelected] = useState({})
  const [upload, setUpload] = useState('')
  const [isKlaviyoSelected, setIsKlaviyoSelected] = useState(false)
  const [isCampaignerSelected, setIsCampaignerSelected] = useState(false)
  const [isEarnwareSelected, setIsEarnwareSelected] = useState(false)
  const [isActiveCampaignSelected, setIsActiveCampaignSelected] = useState(false)
  const [isMaropostSelected, setIsMaropostSelected] = useState(false)
  const [selectableEspList, setSelectableEspList] = useState([])
  const [isSendlaneSelected, setIsSendlaneSelected] = useState(true)

  useEffect(() => {
    loadSettings()
    loadSMSSettings()
    loadWebhookSettings()
    loadWebhookDomainsSettings()
    loadKlaviyoWebhookDomainsSettings()
    loadCampaignerWebhookDomainsSettings()
    loadMaropostWebhookDomainsSettings()
    loadPostscriptWebhookDomainsSettings()
    loadSkippedEmailDomainSettings()
    loadMaropostSettingsData()
    loadKlaviyoSettingsData()
    loadCampaignerSettingsData()
    loadPostscriptSettingsData()
    loadDomainData()
    loadActiveCampaignListData()
  }, [])

  useEffect(() => {
    setNotFound(false)
  }, [])

  const { t } = useTranslation()

  const loadSettings = () => {
    const getSettings = async () => {
      try {
        const response = await findBucketSettings('bucket')
        createSettingsTable(response.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any settings data', 'e')
      }
    }
    getSettings()
  }

  const loadSMSSettings = () => {
    const getSettings = async () => {
      try {
        const response = await findBucketSettings('sms')
        createSMSSettingsTable(response.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any settings data', 'e')
      }
    }
    getSettings()
  }

  const loadWebhookSettings = () => {
    const getWebhookSettings = async () => {
      try {
        const response = await findBucketSettings('webhook_esp')
        createWebhookSettingsTable(response.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any settings data', 'e')
      }
    }
    getWebhookSettings()
  }

  const loadWebhookDomainsSettings = () => {
    const getWebhookDomainsSettings = async () => {
      try {
        const response = await findBucketSettings('webhook_sendlane')
        createWebhookSendlaneDomainsSettingsTable(response.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any settings data', 'e')
      }
    }
    getWebhookDomainsSettings()
  }

  const loadKlaviyoWebhookDomainsSettings = () => {
    const getWebhookDomainsSettings = async () => {
      try {
        const response = await findBucketSettings('webhook_klaviyo')
        createWebhookKlaviyoDomainsSettingsTable(response.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any settings data', 'e')
      }
    }
    getWebhookDomainsSettings()
  }

  const loadCampaignerWebhookDomainsSettings = () => {
    const getWebhookDomainsSettings = async () => {
      try {
        const response = await findBucketSettings('webhook_campaigner')
        createWebhookCampaignerDomainsSettingsTable(response.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any settings data', 'e')
      }
    }
    getWebhookDomainsSettings()
  }

  const loadMaropostWebhookDomainsSettings = () => {
    const getWebhookDomainsSettings = async () => {
      try {
        const response = await findBucketSettings('webhook_maropost')
        createWebhookMaropostDomainsSettingsTable(response.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any settings data', 'e')
      }
    }
    getWebhookDomainsSettings()
  }

  const loadPostscriptWebhookDomainsSettings = () => {
    const getWebhookDomainsSettings = async () => {
      try {
        const response = await findBucketSettings('webhook_postscript')
        createWebhookPostscriptDomainsSettingsTable(response.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any settings data', 'e')
      }
    }
    getWebhookDomainsSettings()
  }

  const loadSkippedEmailDomainSettings = () => {
    const getSkippedEmailDomainSettings = async () => {
      try {
        const response = await findBucketSettings('skipped_email')
        let skippedDomainList = response.data.data[0].properties['skipped_domain_list'].map((d) => {
          return { label: d, value: d }
        })
        setSkippedEmailDomainSettingsData(skippedDomainList)
      } catch (error) {
        setNotFound(true)
        notify('Not found any settings data', 'e')
      }
    }
    getSkippedEmailDomainSettings()
  }

  const createSettingsTable = (data) => {
    let tableData = []
    for (let i = 0; i < data.length; i++) {
      let tableObj = {
        id: data[i].id,
        type: data[i].type,
        name: data[i].properties['name'],
        time_frequency: data[i].properties['time_frequency'],
        size: data[i].properties['size'],
        job_status: data[i].properties['job_status'],
        domain: data[i].properties['domain'],
        bucket_size_remaining: data[i].properties['bucket_size_remaining'],
        bucket_sent_count: data[i].properties['bucket_sent_count'],
        bucket_skipped_domains: data[i].properties['bucket_skipped_domains'],
        esp_system: data[i].properties['esp_system'],
        list_id: data[i].properties['list_id'],
      }
      tableData.push(tableObj)
    }
    setSettingsTableData(tableData)
  }

  const createSMSSettingsTable = (data) => {
    let tableData = []
    for (let i = 0; i < data.length; i++) {
      let tableObj = {
        id: data[i].id,
        type: data[i].type,
        name: data[i].properties['name'],
        time_frequency: data[i].properties['time_frequency'],
        size: data[i].properties['size'],
        job_status: data[i].properties['job_status'],
        bucket_size_remaining: data[i].properties['bucket_size_remaining'],
        bucket_sent_count: data[i].properties['bucket_sent_count'],
        sms_system: data[i].properties['sms_system'],
      }
      tableData.push(tableObj)
    }
    setSmsSettingsTableData(tableData)
  }

  const createWebhookSettingsTable = (data) => {
    let tableData = []
    for (let i = 0; i < data.length; i++) {
      var tableObj = {
        id: data[i].id,
        type: data[i].type,
        esp_list: data[i].properties['esp_list'],
      }
      tableData.push(tableObj)
    }
    setWebhookSettingsTableData(tableData)

    // Check if the sendlane is selected
    if (tableObj.esp_list.indexOf('SENDLANE') > -1) {
      setIsSendlaneSelected(true)
    } else {
      setIsSendlaneSelected(false)
    }

    // Creating selectable esp list
    let selectEspList = []
    selectEspList.push({ label: 'SENDLANE', value: 'SENDLANE' })
    selectEspList.push({ label: 'KLAVIYO', value: 'KLAVIYO' })
    selectEspList.push({ label: 'EARNWARE', value: 'EARNWARE' })
    selectEspList.push({ label: 'ACTIVE CAMPAIGN', value: 'ACTIVE_CAMPAIGN' })
    selectEspList.push({ label: 'MAROPOST', value: 'MAROPOST' })
    selectEspList.push({ label: 'CAMPAIGNER', value: 'CAMPAIGNER' })
    setSelectableEspList(selectEspList)
  }

  const createWebhookSendlaneDomainsSettingsTable = (data) => {
    let tableData = []
    for (let i = 0; i < data.length; i++) {
      let tableObj = {
        id: data[i].id,
        type: data[i].type,
        domain_list: data[i].properties['domain_list'],
      }
      tableData.push(tableObj)
    }
    setWebhookDomainsSettingsTableData(tableData)
  }

  const createWebhookKlaviyoDomainsSettingsTable = (data) => {
    let tableData = []
    for (let i = 0; i < data.length; i++) {
      let tableObj = {
        id: data[i].id,
        type: data[i].type,
        domain_list: data[i].properties['domain_list'],
      }
      tableData.push(tableObj)
    }
    setKlaviyoWebhookSettingsTableData(tableData)
  }

  const createWebhookCampaignerDomainsSettingsTable = (data) => {
    let tableData = []
    for (let i = 0; i < data.length; i++) {
      let tableObj = {
        id: data[i].id,
        type: data[i].type,
        domain_list: data[i].properties['domain_list'],
      }
      tableData.push(tableObj)
    }
    setCampaignerWebhookSettingsTableData(tableData)
  }

  const createWebhookMaropostDomainsSettingsTable = (data) => {
    let tableData = []
    for (let i = 0; i < data.length; i++) {
      let tableObj = {
        id: data[i].id,
        type: data[i].type,
        domain_list: data[i].properties['domain_list'],
      }
      tableData.push(tableObj)
    }
    setMaropostWebhookSettingsTableData(tableData)
  }

  const createWebhookPostscriptDomainsSettingsTable = (data) => {
    let tableData = []
    for (let i = 0; i < data.length; i++) {
      let tableObj = {
        id: data[i].id,
        type: data[i].type,
        domain_list: data[i].properties['domain_list'],
      }
      tableData.push(tableObj)
    }
    setPostscriptWebhookSettingsTableData(tableData)
  }

  const loadDomainData = () => {
    const loadData = async () => {
      try {
        const domainResponse = await findDomains()
        setDomainData(domainResponse.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any domain data', 'e')
      }
    }
    loadData()
  }

  const loadActiveCampaignListData = () => {
    const loadData = async () => {
      try {
        const listResponse = await findActiveCampaignListData()
        setActiveCampaignListData(listResponse.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any list data', 'e')
      }
    }
    loadData()
  }

  const loadMaropostListData = (account) => {
    const loadData = async () => {
      try {
        const listReponse = await findMaropostListData(account)
        let listData = listReponse.data.data.map((listItem) => {
          return { label: listItem.from_name, value: listItem.id }
        })
        setMaropostListData(listData)
      } catch (error) {
        setNotFound(true)
        notify('Not found any list data', 'e')
      }
    }
    loadData()
  }

  const loadKlaviyoListData = (account) => {
    const loadData = async () => {
      try {
        const listResponse = await findKlaviyoListData(account)
        let listData = listResponse.data.data.map((listItem) => {
          return { label: listItem.attributes.name, value: listItem.id }
        })
        setKlaviyoListData(listData)
      } catch (error) {
        setNotFound(true)
        notify('Not found any list data', 'e')
      }
    }
    loadData()
  }

  const loadCampaignerListData = (account) => {
    const loadData = async () => {
      try {
        const listResponse = await findCampaignerListData(account)
        let listData = listResponse.data.data.map((listItem) => {
          return { label: listItem.name, value: listItem.id }
        })
        setCampaignerListData(listData)
      } catch (error) {
        setNotFound(true)
        notify('Not found any list data', 'e')
      }
    }
    loadData()
  }

  const loadMaropostSettingsData = () => {
    const loadData = async () => {
      try {
        const settingsData = await findSettingsByType('maropost_account_list')
        const data = settingsData.data.data

        let accounts = data.properties['accounts']
        let accountDetails = Object.keys(accounts).map((account) => {
          return { label: accounts[account], value: account }
        })
        setMaropostAccountListData(accountDetails)
      } catch (error) {
        setNotFound(true)
        notify('Not found any list data', 'e')
      }
    }
    loadData()
  }

  const loadKlaviyoSettingsData = () => {
    const loadData = async () => {
      try {
        const settingsData = await findSettingsByType('klaviyo_account_list')
        const data = settingsData.data.data

        let accounts = data.properties['accounts']
        let accountDetails = Object.keys(accounts).map((account) => {
          return { label: accounts[account], value: account }
        })
        setKlaviyoAccountListData(accountDetails)
      } catch (error) {
        setNotFound(true)
        notify('Not found any list data', 'e')
      }
    }
    loadData()
  }

  const loadCampaignerSettingsData = () => {
    const loadData = async () => {
      try {
        const settingsData = await findSettingsByType('campaigner_account_list')
        const data = settingsData.data.data

        let accounts = data.properties['accounts']
        let accountDetails = Object.keys(accounts).map((account) => {
          return { label: accounts[account], value: account }
        })
        setCampaignerAccountListData(accountDetails)
      } catch (error) {
        setNotFound(true)
        notify('Not found any list data', 'e')
      }
    }
    loadData()
  }

  const loadPostscriptSettingsData = () => {
    const loadData = async () => {
      try {
        const settingsData = await findSettingsByType('postscript_account_list')
        const data = settingsData.data.data

        let accounts = data.properties['accounts']
        let accountDetails = Object.keys(accounts).map((account) => {
          return { label: accounts[account], value: account }
        })
        setPostscriptAccountListData(accountDetails)
      } catch (error) {
        setNotFound(true)
        notify('Not found any list data', 'e')
      }
    }
    loadData()
  }

  const handleJobStatusCheck = (evt, selectType) => {
    setSelected({
      ...selected,
      job_status: evt.target.checked ? 'in_progress' : 'paused',
    })
  }

  const handleSmsJobStatusCheck = (evt, selectType) => {
    setSmsSelected({
      ...smsSelected,
      job_status: evt.target.checked ? 'in_progress' : 'paused',
    })
  }

  const onChangeSelect = (evt, selectType) => {
    if (selectType === 'time_frequency') {
      setSelected({
        ...selected,
        time_frequency: parseInt(evt.target.value),
      })
    } else if (selectType === 'size') {
      setSelected({
        ...selected,
        size: parseInt(evt.target.value),
      })
    } else if (selectType === 'name') {
      setSelected({
        ...selected,
        name: evt.target.value,
      })
    } else if (selectType === 'domain') {
      setSelected({
        ...selected,
        domain: evt.target.value,
      })
    } else if (selectType === 'bucket_skipped_domains') {
      setSelected({
        ...selected,
        bucket_skipped_domains: evt.map((d) => {
          return d.value
        }),
      })
    } else if (selectType === 'esp_system') {
      setSelected({
        ...selected,
        esp_system: evt.target.value,
        domain: '',
        list_id: '',
      })

      if (evt.target.value === 'KLAVIYO') {
        setIsKlaviyoSelected(true)
        setIsEarnwareSelected(false)
        setIsActiveCampaignSelected(false)
        setIsMaropostSelected(false)
        setIsCampaignerSelected(false)
      } else if (evt.target.value === 'EARNWARE') {
        setIsEarnwareSelected(true)
        setIsKlaviyoSelected(false)
        setIsActiveCampaignSelected(false)
        setIsMaropostSelected(false)
        setIsCampaignerSelected(false)
      } else if (evt.target.value === 'ACTIVE_CAMPAIGN') {
        setIsActiveCampaignSelected(true)
        setIsKlaviyoSelected(false)
        setIsEarnwareSelected(false)
        setIsMaropostSelected(false)
        setIsCampaignerSelected(false)
      } else if (evt.target.value === 'MAROPOST') {
        setIsMaropostSelected(true)
        setIsActiveCampaignSelected(false)
        setIsKlaviyoSelected(false)
        setIsEarnwareSelected(false)
        setIsCampaignerSelected(false)
      } else if (evt.target.value === 'CAMPAIGNER') {
        setIsCampaignerSelected(true)
        setIsActiveCampaignSelected(false)
        setIsKlaviyoSelected(false)
        setIsEarnwareSelected(false)
        setIsMaropostSelected(false)
      } else {
        setIsCampaignerSelected(false)
        setIsKlaviyoSelected(false)
        setIsEarnwareSelected(false)
        setIsActiveCampaignSelected(false)
        setIsMaropostSelected(false)
      }
    } else if (selectType === 'list_id') {
      setSelected({
        ...selected,
        list_id: evt.target.value,
      })
    }
  }

  const onChangeSelectSms = (evt, selectType) => {
    if (selectType === 'time_frequency') {
      setSmsSelected({
        ...smsSelected,
        time_frequency: parseInt(evt.target.value),
      })
    } else if (selectType === 'size') {
      setSmsSelected({
        ...smsSelected,
        size: parseInt(evt.target.value),
      })
    } else if (selectType === 'name') {
      setSmsSelected({
        ...smsSelected,
        name: evt.target.value,
      })
    } else if (selectType === 'sms_system') {
      setSmsSelected({
        ...smsSelected,
        sms_system: evt.target.value,
      })
    } else if (selectType === 'domain') {
      setSmsSelected({
        ...smsSelected,
        domain: evt.target.value,
      })
    }
  }

  const onClickSettingsUpdate = (index, system) => {
    let updateObj = {
      properties: {},
    }
    if (system === 'email') {
      Object.keys(selected).map((e) => {
        if (e === 'id' || e === 'type') {
          updateObj[e] = selected[e]
        } else {
          updateObj.properties[e] = selected[e]
        }
      })
    } else if (system === 'sms') {
      Object.keys(smsSelected).map((e) => {
        if (e === 'id' || e === 'type') {
          updateObj[e] = smsSelected[e]
        } else {
          updateObj.properties[e] = smsSelected[e]
        }
      })
    }

    const updateData = async () => {
      try {
        await updateSettings(updateObj)
        loadSettings()
        loadSMSSettings()
        notify('Settings Updated!', 's')
      } catch (error) {
        setNotFound(true)
        notify('Update Error!', 'e')
      }
    }

    const jobOperation = async (index, operation) => {
      try {
        await bucketJobOperation(index, operation, updateObj)
      } catch (error) {
        setNotFound(true)
        notify('Job Operation Error!', 'e')
      }
    }
    updateData()
    jobOperation(index, EVENT_RESCHEDULE)
    if (system === 'email') {
      toggleSettingsDetails(index - 1)
    } else if (system === 'sms') {
      toggleSmsSettingsDetails(index - 1)
    }
  }

  const onChangeUpload = (type, evt) => {
    let file = evt.target.files[0]
    if (file) {
      const uploadFile = async (type, file) => {
        setUpload(type)
        try {
          await uploadBuckets(type, file)
          loadSettings()
          loadSMSSettings()
          setUpload('')
        } catch (error) {
          setUpload('')
          notify('Upload File Error', 'e')
        }
      }
      uploadFile(type, file)
    }
  }

  const onDeleteBuckets = (type) => {
    const deleteBuckets = async (type) => {
      setUpload(type)
      try {
        await deleteBucketsByBucketNo(type)
        loadSettings()
        loadSMSSettings()
        setUpload('')
      } catch (error) {
        notify('Received an error while deleting buckets!', 'e')
        setUpload('')
      }
    }
    deleteBuckets(type)
  }

  const toggleSettingsDetails = (index) => {
    const position = settingsDetails.indexOf(index)
    let newDetails = settingsDetails.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [index]
    }
    setSettingsDetails(newDetails)
    setSelected(settingsTableData[index])

    // controlling: if klaviyo or earnware or active campaign is active for bucket
    let esp = settingsTableData[index].esp_system
    if (esp === 'KLAVIYO') {
      setIsKlaviyoSelected(true)
      setIsEarnwareSelected(false)
      setIsActiveCampaignSelected(false)
      setIsMaropostSelected(false)
      setIsCampaignerSelected(false)
      loadKlaviyoListData(settingsTableData[index].domain)
    } else if (esp === 'EARNWARE') {
      setIsEarnwareSelected(true)
      setIsKlaviyoSelected(false)
      setIsActiveCampaignSelected(false)
      setIsMaropostSelected(false)
      setIsCampaignerSelected(false)
    } else if (esp === 'ACTIVE_CAMPAIGN') {
      setIsActiveCampaignSelected(true)
      setIsKlaviyoSelected(false)
      setIsEarnwareSelected(false)
      setIsMaropostSelected(false)
      setIsCampaignerSelected(false)
    } else if (esp === 'MAROPOST') {
      setIsMaropostSelected(true)
      setIsActiveCampaignSelected(false)
      setIsKlaviyoSelected(false)
      setIsEarnwareSelected(false)
      setIsCampaignerSelected(false)
      loadMaropostListData(settingsTableData[index].domain)
    } else if (esp === 'CAMPAIGNER') {
      setIsCampaignerSelected(true)
      setIsMaropostSelected(false)
      setIsActiveCampaignSelected(false)
      setIsKlaviyoSelected(false)
      setIsEarnwareSelected(false)
      loadCampaignerListData(settingsTableData[index].domain)
    } else {
      setIsCampaignerSelected(false)
      setIsKlaviyoSelected(false)
      setIsEarnwareSelected(false)
      setIsActiveCampaignSelected(false)
      setIsMaropostSelected(false)
    }
  }

  const toggleSmsSettingsDetails = (index) => {
    const position = smsSettingsDetails.indexOf(index)
    let newDetails = smsSettingsDetails.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [index]
    }
    setSmsSettingsDetails(newDetails)
    setSmsSelected(smsSettingsTableData[index])
  }

  const handleEspListSelectChange = (evt, item) => {
    item.esp_list = []
    evt.map((esp) => item.esp_list.push(esp.value))
    handleUpdateSettings(item)
  }

  const handleDomainListSelectChange = (evt, item) => {
    item.domain_list = []
    evt.map((domain) => item.domain_list.push(domain.value))
    handleUpdateSettings(item)
  }

  const handleUpdateSettings = (obj) => {
    let updateObj = {
      properties: {},
    }
    Object.keys(obj).map((e) => {
      if (e === 'id' || e === 'type') {
        updateObj[e] = obj[e]
      } else {
        updateObj.properties[e] = obj[e]
      }
    })

    const updateWebhookSettings = async () => {
      try {
        await updateSettings(updateObj)
        loadWebhookSettings()
        notify('Settings Updated!', 's')
      } catch (error) {
        setNotFound(true)
        notify('Update Error!', 'e')
      }
    }

    updateWebhookSettings()
  }

  const fields = [
    //{ key: 'type', label: 'Type', _style: { width: '10%' } },
    { key: 'name', label: 'Name', _style: { width: '15%' } },
    { key: 'time_frequency', _style: { width: '15%' } },
    { key: 'size', _style: { width: '15%' } },
    { key: 'domain', _style: { width: '20%' } },
    { key: 'esp_system', label: 'Provider', _style: { width: '15%' } },
    { key: 'job_status', _style: { width: '20%' } },
    {
      key: 'show_details',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ]

  const smsFields = [
    //{ key: 'type', label: 'Type', _style: { width: '10%' } },
    { key: 'name', label: 'Name', _style: { width: '15%' } },
    { key: 'time_frequency', _style: { width: '15%' } },
    { key: 'size', _style: { width: '15%' } },
    { key: 'sms_system', label: 'Provider', _style: { width: '15%' } },
    { key: 'job_status', _style: { width: '20%' } },
    {
      key: 'show_details',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ]

  const webhook_fields = [
    { key: 'type', label: 'Name', _style: { width: '25%' } },
    { key: 'esp_list', label: 'Assigned ESPs', _style: { width: '75%' } },
  ]

  const webhook_domains_fields = [
    { key: 'type', label: 'Name', _style: { width: '25%' } },
    { key: 'domain_list', label: 'Assigned domains', _style: { width: '75%' } },
  ]

  const getBadge = (status) => {
    switch (status) {
      case 'in_progress':
        return 'success'
      case 'not_started':
        return 'secondary'
      case 'paused':
        return 'warning'
      default:
        return 'primary'
    }
  }

  const getBadgeText = (status) => {
    switch (status) {
      case 'in_progress':
        return 'IN PROGRESS'
      case 'not_started':
        return 'NOT STARTED'
      case 'paused':
        return 'PAUSED'
      default:
        return 'UNDEFINED'
    }
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const BucketBody = (bucket) => (
    <>
      <CCardTitle>{bucket.name}</CCardTitle>
      <CCardSubtitle className="mb-2 text-muted">
        <CBadge color={getBadge(bucket.job_status)}>{getBadgeText(bucket.job_status)}</CBadge>
      </CCardSubtitle>
      <CFormGroup row>
        <CCol className="mt-3" md="12">
          <CInputFile id="custom-file-input" onChange={onChangeUpload.bind(this, bucket.type)} />
        </CCol>
      </CFormGroup>
      <CLabel>Current Bucket: </CLabel>
      <CInput disabled value={bucket.bucket_size_remaining}></CInput>
      <CLabel>Sent Bucket: </CLabel>
      <CInput disabled value={bucket.bucket_sent_count}></CInput>
    </>
  )

  return (
    <div className="container ml-auto" style={{ padding: '20px' }}>
      <div>
        <CCard>
          <CCardBody>
            <CTabs activeTab="bucketInfo">
              <CNav letiant="tabs">
                <CNavItem>
                  <CNavLink data-tab="bucketInfo">Email Bucket</CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink data-tab="settings">Email Settings</CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink data-tab="smsBucketInfo">SMS Bucket</CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink data-tab="smsSettings">SMS Settings</CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink data-tab="webhookSettings">Webhook</CNavLink>
                </CNavItem>
              </CNav>
              <CTabContent>
                <CTabPane data-tab="bucketInfo">
                  <ImportFileAlert
                    fields="Email, Phone Number,
                          First Name, Last Name"
                  />
                  <CRow>
                    {settingsTableData.map((bucket) => (
                      <CCol sm="3" className="mt-2">
                        <CCard>
                          <CCardHeader>
                            {bucket.esp_system === 'SENDLANE' ? (
                              <img src={sendlane} width="30" />
                            ) : bucket.esp_system === 'KLAVIYO' ? (
                              <img src={klaviyo} width="30" />
                            ) : bucket.esp_system === 'CAMPAIGNER' ? (
                              <img src={campaigner} width="30" />
                            ) : bucket.esp_system === 'ACTIVE_CAMPAIGN' ? (
                              <img src={active_campaign} width="30" />
                            ) : bucket.esp_system === 'MAROPOST' ? (
                              <img src={maropost} width="30" />
                            ) : (
                              <img
                                style={{
                                  backgroundColor: '#136ef2',
                                }}
                                src={earnware}
                                width="30"
                              />
                            )}

                            {`  ${bucket.esp_system}`}

                            <CButton
                              style={
                                bucket.esp_system === 'EARNWARE'
                                  ? { marginLeft: '70px' }
                                  : bucket.esp_system === 'KLAVIYO'
                                  ? { marginLeft: '95px' }
                                  : bucket.esp_system === 'CAMPAIGNER'
                                  ? { marginLeft: '60px' }
                                  : bucket.esp_system === 'ACTIVE_CAMPAIGN'
                                  ? { marginLeft: '25px' }
                                  : bucket.esp_system === 'MAROPOST'
                                  ? { marginLeft: '25px' }
                                  : { marginLeft: '80px' }
                              }
                              color="danger"
                              letiant="outline"
                              shape="square"
                              size="sm"
                              onClick={() => {
                                onDeleteBuckets(bucket.type)
                              }}
                            >
                              Delete
                            </CButton>
                          </CCardHeader>
                          <CCardBody>
                            {upload === bucket.type ? <Spinner /> : BucketBody(bucket)}
                          </CCardBody>
                        </CCard>
                      </CCol>
                    ))}
                  </CRow>
                </CTabPane>
                <CTabPane data-tab="settings">
                  <CDataTable
                    size="sm"
                    items={settingsTableData}
                    fields={fields}
                    hover
                    scopedSlots={{
                      domain: (item) =>
                        item.esp_system === 'SENDLANE' ||
                        item.esp_system === 'KLAVIYO' ||
                        item.esp_system === 'CAMPAIGNER' ||
                        item.esp_system === 'MAROPOST' ||
                        item.esp_system === 'POSTSCRIPT' ? (
                          <td>{item.domain}</td>
                        ) : (
                          <td></td>
                        ),
                      time_frequency: (item) => <td>{item.time_frequency} by hour</td>,
                      name: (item) => <td>{item.name}</td>,
                      size: (item) => <td>{item.size} by day</td>,
                      job_status: (item) => (
                        <td>
                          <CBadge color={getBadge(item.job_status)}>
                            {getBadgeText(item.job_status)}
                          </CBadge>
                        </td>
                      ),
                      show_details: (item, index) => {
                        return (
                          <td className="py-2">
                            <CButton
                              color="primary"
                              letiant="outline"
                              shape="square"
                              size="sm"
                              onClick={() => {
                                toggleSettingsDetails(index)
                              }}
                            >
                              {settingsDetails.includes(index) ? 'Hide' : 'Show'}
                            </CButton>
                          </td>
                        )
                      },
                      details: (item, index) => {
                        return (
                          <CCollapse show={settingsDetails.includes(index)}>
                            <CCardBody>
                              <CRow>
                                <CCol sm="2">
                                  <CForm>
                                    <CFormGroup>
                                      <CLabel>Bucket Name</CLabel>
                                      <CInput
                                        type="text"
                                        maxLength={20}
                                        defaultValue={selected.name}
                                        onChange={(evt) => onChangeSelect(evt, 'name')}
                                      ></CInput>
                                    </CFormGroup>
                                  </CForm>
                                </CCol>

                                <CCol sm="1">
                                  <CForm>
                                    <CFormGroup>
                                      <CLabel>Hour</CLabel>
                                      <CSelect
                                        value={selected.time_frequency}
                                        onChange={(evt) => onChangeSelect(evt, 'time_frequency')}
                                      >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="6">6</option>
                                      </CSelect>
                                    </CFormGroup>
                                  </CForm>
                                </CCol>
                                <CCol sm="2">
                                  <CForm>
                                    <CFormGroup>
                                      <CLabel>Size(Daily)</CLabel>
                                      <CSelect
                                        value={selected.size}
                                        onChange={(evt) => onChangeSelect(evt, 'size')}
                                      >
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="400">400</option>
                                        <option value="600">600</option>
                                        <option value="1000">1000</option>
                                        <option value="3000">3000</option>
                                        <option value="5000">5000</option>
                                      </CSelect>
                                    </CFormGroup>
                                  </CForm>
                                </CCol>

                                <CCol sm="2">
                                  <CForm>
                                    <CFormGroup>
                                      <CLabel>ESP System</CLabel>
                                      <CSelect
                                        value={selected.esp_system}
                                        onChange={(evt) => onChangeSelect(evt, 'esp_system')}
                                      >
                                        <option value="SENDLANE">Sendlane</option>
                                        <option value="KLAVIYO">Klaviyo</option>
                                        <option value="CAMPAIGNER">Campaigner</option>
                                        <option value="EARNWARE">Earnware</option>
                                        <option value="ACTIVE_CAMPAIGN">Active Campaign</option>
                                        <option value="MAROPOST">Maropost</option>
                                      </CSelect>
                                    </CFormGroup>
                                  </CForm>
                                </CCol>
                                {!isKlaviyoSelected &&
                                  !isCampaignerSelected &&
                                  !isEarnwareSelected &&
                                  !isActiveCampaignSelected &&
                                  !isMaropostSelected && (
                                    <CCol sm="3">
                                      <CForm>
                                        <CFormGroup>
                                          <CLabel>Select Domain</CLabel>
                                          <CSelect
                                            value={selected.domain}
                                            onChange={(evt) => onChangeSelect(evt, 'domain')}
                                          >
                                            {domainData.map((domain) => {
                                              return (
                                                <option value={domain.domainInfo}>
                                                  {domain.domainInfo}
                                                </option>
                                              )
                                            })}
                                          </CSelect>
                                        </CFormGroup>
                                      </CForm>
                                    </CCol>
                                  )}

                                {isKlaviyoSelected && (
                                  <CCol sm="4">
                                    <CRow>
                                      <CForm>
                                        <CFormGroup>
                                          <CLabel>Select Account</CLabel>
                                          <CSelect
                                            value={selected.domain}
                                            onChange={(evt) => {
                                              loadKlaviyoListData(evt.target.value)
                                              onChangeSelect(evt, 'domain')
                                            }}
                                          >
                                            <option></option>
                                            {klaviyoAccountListData.map((account) => {
                                              return (
                                                <option value={account.value}>
                                                  {account.label}
                                                </option>
                                              )
                                            })}
                                          </CSelect>
                                        </CFormGroup>
                                      </CForm>
                                    </CRow>
                                    <CRow>
                                      <CForm>
                                        <CFormGroup>
                                          <CLabel>Select List</CLabel>
                                          <CSelect
                                            value={selected.list_id}
                                            onChange={(evt) => onChangeSelect(evt, 'list_id')}
                                          >
                                            <option></option>
                                            {klaviyoListData.map((listItem) => {
                                              return (
                                                <option value={listItem.value}>
                                                  {listItem.label}
                                                </option>
                                              )
                                            })}
                                          </CSelect>
                                        </CFormGroup>
                                      </CForm>
                                    </CRow>
                                  </CCol>
                                )}

                                {isCampaignerSelected && (
                                  <CCol sm="4">
                                    <CRow>
                                      <CForm>
                                        <CFormGroup>
                                          <CLabel>Select Account</CLabel>
                                          <CSelect
                                            value={selected.domain}
                                            onChange={(evt) => {
                                              loadCampaignerListData(evt.target.value)
                                              onChangeSelect(evt, 'domain')
                                            }}
                                          >
                                            <option></option>
                                            {campaignerAccountListData.map((account) => {
                                              return (
                                                <option value={account.value}>
                                                  {account.label}
                                                </option>
                                              )
                                            })}
                                          </CSelect>
                                        </CFormGroup>
                                      </CForm>
                                    </CRow>
                                    <CRow>
                                      <CForm>
                                        <CFormGroup>
                                          <CLabel>Select List</CLabel>
                                          <CSelect
                                            value={selected.list_id}
                                            onChange={(evt) => onChangeSelect(evt, 'list_id')}
                                          >
                                            <option></option>
                                            {campaignerListData.map((listItem) => {
                                              return (
                                                <option value={listItem.value}>
                                                  {listItem.label}
                                                </option>
                                              )
                                            })}
                                          </CSelect>
                                        </CFormGroup>
                                      </CForm>
                                    </CRow>
                                  </CCol>
                                )}

                                {isActiveCampaignSelected && (
                                  <CCol sm="2">
                                    <CForm>
                                      <CFormGroup>
                                        <CLabel>Select List</CLabel>
                                        <CSelect
                                          value={selected.list_id}
                                          onChange={(evt) => onChangeSelect(evt, 'list_id')}
                                        >
                                          <option></option>
                                          {activeCampaignListData.map((esp) => {
                                            return <option value={esp.id}>{esp.name}</option>
                                          })}
                                        </CSelect>
                                      </CFormGroup>
                                    </CForm>
                                  </CCol>
                                )}

                                {isMaropostSelected && (
                                  <CCol sm="4">
                                    <CRow>
                                      <CForm>
                                        <CFormGroup>
                                          <CLabel>Select Account</CLabel>
                                          <CSelect
                                            value={selected.domain}
                                            onChange={(evt) => {
                                              loadMaropostListData(evt.target.value)
                                              onChangeSelect(evt, 'domain')
                                            }}
                                          >
                                            <option></option>
                                            {maropostAccountListData.map((account) => {
                                              return (
                                                <option value={account.value}>
                                                  {account.label}
                                                </option>
                                              )
                                            })}
                                          </CSelect>
                                        </CFormGroup>
                                      </CForm>
                                    </CRow>
                                    <CRow>
                                      <CForm>
                                        <CFormGroup>
                                          <CLabel>Select List</CLabel>
                                          <CSelect
                                            value={selected.list_id}
                                            onChange={(evt) => onChangeSelect(evt, 'list_id')}
                                          >
                                            <option></option>
                                            {maropostListData.map((listItem) => {
                                              return (
                                                <option value={listItem.value}>
                                                  {listItem.label}
                                                </option>
                                              )
                                            })}
                                          </CSelect>
                                        </CFormGroup>
                                      </CForm>
                                    </CRow>
                                  </CCol>
                                )}

                                <CCol sm="1">
                                  <CForm>
                                    <CFormGroup>
                                      <CLabel>Job Status</CLabel>
                                      <div>
                                        <CSwitch
                                          className="mt-1 ml-1"
                                          checked={
                                            !(selected.job_status === 'not_started' ||
                                              selected.job_status === 'paused')
                                          }
                                          color={
                                            selected.job_status === 'not_started' ||
                                            selected.job_status === 'paused'
                                              ? 'danger'
                                              : 'success'
                                          }
                                          letiant="3d"
                                          shape="pill"
                                          labelOn={'\u2713'}
                                          labelOff={'\u2715'}
                                          onChange={(evt) => handleJobStatusCheck(evt)}
                                        />
                                      </div>
                                    </CFormGroup>
                                  </CForm>
                                </CCol>

                                <CCol sm="4">
                                  <CForm>
                                    <CFormGroup>
                                      <CLabel>Skipped Domains</CLabel>
                                      <div>
                                        <Select
                                          menuPortalTarget={document.querySelector('body')}
                                          placeholder="Select skipped email domains.."
                                          value={
                                            Array.isArray(selected.bucket_skipped_domains) &&
                                            selected.bucket_skipped_domains.map((obj) => ({
                                              label: obj,
                                              value: obj,
                                            }))
                                          }
                                          options={skippedEmailDomainSettingsData}
                                          onChange={(evt) =>
                                            onChangeSelect(evt, 'bucket_skipped_domains')
                                          }
                                          isMulti
                                          isClearable
                                        />
                                      </div>
                                    </CFormGroup>
                                  </CForm>
                                </CCol>
                              </CRow>
                              <CButton
                                size="sm"
                                color="info"
                                onClick={() => onClickSettingsUpdate(index + 1, 'email')}
                              >
                                Update Settings
                              </CButton>
                            </CCardBody>
                          </CCollapse>
                        )
                      },
                    }}
                  />
                </CTabPane>
                <CTabPane data-tab="smsBucketInfo">
                  <ImportFileAlert
                    fields="Email, Phone Number,
                          First Name, Last Name"
                  />
                  <CRow>
                    {smsSettingsTableData.map((bucket) => (
                      <CCol sm="3" className="mt-2">
                        <CCard>
                          <CCardHeader>
                            <img src={postscript} width="30" />

                            {`  ${bucket.sms_system}`}
                            <CButton
                              style={{ marginLeft: '75px' }}
                              color="danger"
                              letiant="outline"
                              shape="square"
                              size="sm"
                              onClick={() => {
                                onDeleteBuckets(bucket.type)
                              }}
                            >
                              Delete
                            </CButton>
                          </CCardHeader>
                          <CCardBody>
                            {upload === bucket.type ? <Spinner /> : BucketBody(bucket)}
                          </CCardBody>
                        </CCard>
                      </CCol>
                    ))}
                  </CRow>
                </CTabPane>
                <CTabPane data-tab="smsSettings">
                  <CDataTable
                    size="sm"
                    items={smsSettingsTableData}
                    fields={smsFields}
                    hover
                    scopedSlots={{
                      time_frequency: (item) => <td>{item.time_frequency} by hour</td>,
                      name: (item) => <td>{item.name}</td>,
                      size: (item) => <td>{item.size} by day</td>,
                      sms_system: (item) => <td>{item.sms_system}</td>,
                      job_status: (item) => (
                        <td>
                          <CBadge color={getBadge(item.job_status)}>
                            {getBadgeText(item.job_status)}
                          </CBadge>
                        </td>
                      ),
                      show_details: (item, index) => {
                        return (
                          <td className="py-2">
                            <CButton
                              color="primary"
                              letiant="outline"
                              shape="square"
                              size="sm"
                              onClick={() => {
                                toggleSmsSettingsDetails(index)
                              }}
                            >
                              {smsSettingsDetails.includes(index) ? 'Hide' : 'Show'}
                            </CButton>
                          </td>
                        )
                      },
                      details: (item, index) => {
                        return (
                          <CCollapse show={smsSettingsDetails.includes(index)}>
                            <CCardBody>
                              <CRow>
                                <CCol sm="2">
                                  <CForm>
                                    <CFormGroup>
                                      <CLabel>Bucket Name</CLabel>
                                      <CInput
                                        type="text"
                                        maxLength={20}
                                        defaultValue={smsSelected.name}
                                        onChange={(evt) => onChangeSelectSms(evt, 'name')}
                                      ></CInput>
                                    </CFormGroup>
                                  </CForm>
                                </CCol>

                                <CCol sm="1">
                                  <CForm>
                                    <CFormGroup>
                                      <CLabel>Hour</CLabel>
                                      <CSelect
                                        value={smsSelected.time_frequency}
                                        onChange={(evt) => onChangeSelectSms(evt, 'time_frequency')}
                                      >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="6">6</option>
                                      </CSelect>
                                    </CFormGroup>
                                  </CForm>
                                </CCol>
                                <CCol sm="2">
                                  <CForm>
                                    <CFormGroup>
                                      <CLabel>Size(Daily)</CLabel>
                                      <CSelect
                                        value={smsSelected.size}
                                        onChange={(evt) => onChangeSelectSms(evt, 'size')}
                                      >
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="400">400</option>
                                        <option value="600">600</option>
                                        <option value="1000">1000</option>
                                        <option value="3000">3000</option>
                                        <option value="5000">5000</option>
                                      </CSelect>
                                    </CFormGroup>
                                  </CForm>
                                </CCol>

                                <CCol sm="2">
                                  <CForm>
                                    <CFormGroup>
                                      <CLabel>SMS System</CLabel>
                                      <CSelect
                                        disabled
                                        value={smsSelected.sms_system}
                                        onChange={(evt) => onChangeSelectSms(evt, 'sms_system')}
                                      >
                                        <option value="POSTSCRIPT">Postscript</option>
                                      </CSelect>
                                    </CFormGroup>
                                  </CForm>
                                </CCol>
                                <CCol sm="2">
                                  <CRow>
                                    <CForm>
                                      <CFormGroup>
                                        <CLabel>Select Account</CLabel>
                                        <CSelect
                                          value={smsSelected.domain}
                                          onChange={(evt) => {
                                            onChangeSelectSms(evt, 'domain')
                                          }}
                                        >
                                          <option></option>
                                          {postscriptAccountListData.map((account) => {
                                            return (
                                              <option value={account.value}>{account.label}</option>
                                            )
                                          })}
                                        </CSelect>
                                      </CFormGroup>
                                    </CForm>
                                  </CRow>
                                </CCol>
                                <CCol sm="1">
                                  <CForm>
                                    <CFormGroup>
                                      <CLabel>Job Status</CLabel>
                                      <div>
                                        <CSwitch
                                          className="mt-1 ml-1"
                                          checked={
                                            !(smsSelected.job_status === 'not_started' ||
                                              smsSelected.job_status === 'paused')
                                          }
                                          color={
                                            smsSelected.job_status === 'not_started' ||
                                            smsSelected.job_status === 'paused'
                                              ? 'danger'
                                              : 'success'
                                          }
                                          letiant="3d"
                                          shape="pill"
                                          labelOn={'\u2713'}
                                          labelOff={'\u2715'}
                                          onChange={(evt) => handleSmsJobStatusCheck(evt)}
                                        />
                                      </div>
                                    </CFormGroup>
                                  </CForm>
                                </CCol>
                              </CRow>
                              <CButton
                                size="sm"
                                color="info"
                                onClick={() => onClickSettingsUpdate(index + 1, 'sms')}
                              >
                                Update Settings
                              </CButton>
                            </CCardBody>
                          </CCollapse>
                        )
                      },
                    }}
                  />
                </CTabPane>
                <CTabPane data-tab="webhookSettings">
                  <CDataTable
                    size="sm"
                    items={webhookSettingsTableData}
                    fields={webhook_fields}
                    hover
                    scopedSlots={{
                      esp_list: (item, index) => {
                        return (
                          <td>
                            <Select
                              menuPortalTarget={document.querySelector('body')}
                              placeholder="Select esp systems.."
                              value={item.esp_list.map((obj) => ({
                                label: obj,
                                value: obj,
                              }))}
                              options={selectableEspList}
                              onChange={(evt) => handleEspListSelectChange(evt, item)}
                              isMulti
                              isClearable
                            />
                          </td>
                        )
                      },
                    }}
                  />
                  <CDataTable
                    size="sm"
                    items={webhookDomainsSettingsTableData}
                    fields={webhook_domains_fields}
                    hover
                    scopedSlots={{
                      domain_list: (item, index) => {
                        return (
                          <td>
                            <Select
                              menuPortalTarget={document.querySelector('body')}
                              placeholder="Select domains.."
                              value={item.domain_list.map((obj) => ({
                                label: obj,
                                value: obj,
                              }))}
                              options={domainData.map((domain) => ({
                                label: domain.domainInfo,
                                value: domain.domainInfo,
                              }))}
                              onChange={(evt) => handleDomainListSelectChange(evt, item)}
                              isMulti
                              isClearable
                            />
                          </td>
                        )
                      },
                    }}
                  />
                  <CDataTable
                    size="sm"
                    items={campaignerWebhookSettingsTableData}
                    fields={webhook_domains_fields}
                    hover
                    scopedSlots={{
                      domain_list: (item, index) => {
                        return (
                          <td>
                            <Select
                              menuPortalTarget={document.querySelector('body')}
                              placeholder="Select account.."
                              value={item.domain_list.map((obj) => ({
                                label: obj,
                                value: obj,
                              }))}
                              options={campaignerAccountListData.map((account) => ({
                                label: account.label,
                                value: account.value,
                              }))}
                              onChange={(evt) => handleDomainListSelectChange(evt, item)}
                              isMulti
                              isClearable
                            />
                          </td>
                        )
                      },
                    }}
                  />
                  <CDataTable
                    size="sm"
                    items={klaviyoWebhookSettingsTableData}
                    fields={webhook_domains_fields}
                    hover
                    scopedSlots={{
                      domain_list: (item, index) => {
                        return (
                          <td>
                            <Select
                              menuPortalTarget={document.querySelector('body')}
                              placeholder="Select account.."
                              value={item.domain_list.map((obj) => ({
                                label: obj,
                                value: obj,
                              }))}
                              options={klaviyoAccountListData.map((account) => ({
                                label: account.label,
                                value: account.value,
                              }))}
                              onChange={(evt) => handleDomainListSelectChange(evt, item)}
                              isMulti
                              isClearable
                            />
                          </td>
                        )
                      },
                    }}
                  />
                  <CDataTable
                    size="sm"
                    items={maropostWebhookSettingsTableData}
                    fields={webhook_domains_fields}
                    hover
                    scopedSlots={{
                      domain_list: (item, index) => {
                        return (
                          <td>
                            <Select
                              menuPortalTarget={document.querySelector('body')}
                              placeholder="Select account.."
                              value={item.domain_list.map((obj) => ({
                                label: obj,
                                value: obj,
                              }))}
                              options={maropostAccountListData.map((account) => ({
                                label: account.label,
                                value: account.value,
                              }))}
                              onChange={(evt) => handleDomainListSelectChange(evt, item)}
                              isMulti
                              isClearable
                            />
                          </td>
                        )
                      },
                    }}
                  />
                  <CDataTable
                    size="sm"
                    items={postscriptWebhookSettingsTableData}
                    fields={webhook_domains_fields}
                    hover
                    scopedSlots={{
                      domain_list: (item, index) => {
                        return (
                          <td>
                            <Select
                              menuPortalTarget={document.querySelector('body')}
                              placeholder="Select account.."
                              value={item.domain_list.map((obj) => ({
                                label: obj,
                                value: obj,
                              }))}
                              options={postscriptAccountListData.map((account) => ({
                                label: account.label,
                                value: account.value,
                              }))}
                              onChange={(evt) => handleDomainListSelectChange(evt, item)}
                              isMulti
                              isClearable
                            />
                          </td>
                        )
                      },
                    }}
                  />
                </CTabPane>
              </CTabContent>
            </CTabs>
          </CCardBody>
        </CCard>
      </div>
    </div>
  )
}

export default BucketPage
