import React, { useEffect, useState } from 'react'
import {
  createPatriotAddictCampaign, findContactData,
  findPatriotAddictCampaignData,
  findPatriotAddictMemberBySegment,
  findPatriotAddictSmsDashboardData,
  findSettingsByType,
  unsubscribePatriotAddictContact,
  uploadPatriotAddictContact,
} from '../api/apiCalls'
import { toast } from 'react-toastify'
import {
  CAlert,
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CForm,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CNav,
  CNavItem,
  CNavLink,
  CPagination,
  CSwitch,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react'
import ModalSelect from '../components/ModalSelect'
import { SETTING_CAMPAIGN_SEGMENT_LIST } from '../constant/SettingsType'
import ModalInput from '../components/ModalInput'
import SmsMessage from '../components/SmsMessage'
import ReviewMessage from '../components/ReviewMessage'
import DatePicker from 'react-datepicker'
import { formatNumber } from '../helper/Formatter'
import CIcon from '@coreui/icons-react'
import SmsKPI from '../views/widgets/SmsKPI'
import { format } from 'date-fns';

toast.configure()
const SmsCampaignPatriotAddictPage = () => {
  // Modal Types
  const MODAL_UPLOAD_PHONE = 'MODAL_UPLOAD_PHONE'
  const MODAL_CREATE_CAMPAIGN = 'MODAL_CREATE_CAMPAIGN'

  const [isShow, setIsShow] = useState({
    MODAL_UPLOAD_PHONE: false,
    MODAL_CREATE_CAMPAIGN: false,
  })

  const [selected, setSelected] = useState({
    segmentName: {
      value: null,
      label: null,
    },
  })

  const [data, setData] = useState({
    campaignName: null,
    shopName: null,
    productUrl: null,
    smsMessage: '',
    reviewMessage: '',
    isReplyStopCheck: false,
  })

  // Error
  const [inputError, setInputError] = useState({
    campaignNameError: null,
    productUrlError: null,
    shopNameError: null,
  })

  const [scheduleDate, setScheduleDate] = useState(new Date())

  // Campaign Table Integration
  const [campaignItems, setCampaignItems] = useState([])
  const [campaignPage, setCampaignPage] = useState(1)
  const [campaignPages, setCampaignPages] = useState(100)
  const [campaignItemsPerPage, setCampaignItemsPerPage] = useState(10)
  const [campaignTableFilterValue, setCampaignTableFilterValue] = useState('')
  const [smsDashboard, setSmsDashboard] = useState()
  const [filterRange, setFilterRange] = useState('LAST_MONTH')

  const campaignTableParams = {
    campaignPage,
    campaignItemsPerPage,
    campaignTableFilterValue,
  }

  // Contact Table Integration
  const [contactItems, setContactItems] = useState([])
  const [contactPage, setContactPage] = useState(1)
  const [contactPages, setContactPages] = useState(100)
  const [contactItemsPerPage, setContactItemsPerPage] = useState(10)
  const [contactTableFilterValue, setContactTableFilterValue] = useState('')

  const contactTableParams = {
    contactPage,
    contactItemsPerPage,
    contactTableFilterValue,
  }

  // Campaign Error
  const [notFound, setNotFound] = useState(false)
  const [loading, setLoading] = useState(true)
  const [contactLoading, setContactLoading] = useState(true)
  const [campaignLoading, setCampaignLoading] = useState(true)
  const [selectedFiles, setSelectedFiles] = useState(undefined)
  const [segments, setSegments] = useState([])
  const [segmentMemberInfo, setSegmentMemberInfo] = useState()
  const [currentFile, setCurrentFile] = useState(undefined)
  const [defaultSmsMessage, setDefaultSmsMessage] = useState(
    '{shopName}: hi, todays deal is 50% off on monocular telescopes. {productUrl} use code 50off.',
  )

  useEffect(() => {
    loadSegmentSettings()
  }, [])

  useEffect(() => {
    loadSmsDashboardData()
  }, [])

  useEffect(() => {
    loadCampaignData()
  }, [campaignPage, campaignItemsPerPage, campaignTableFilterValue])

  useEffect(() => {
    loadContactData()
  }, [contactPage, contactItemsPerPage, contactTableFilterValue])

  const loadSmsDashboardData = async (range) => {
    setLoading(true)

    let filter
    if (range) {
      filter = {
        range: range,
      }
    } else {
      filter = {
        range: filterRange,
      }
    }

    setFilterRange(range)
    try {
      const response = await findPatriotAddictSmsDashboardData(filter)
      setSmsDashboard(response.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const loadCampaignData = async (defaultData) => {
    setCampaignLoading(true)
    let params
    if (defaultData) {
      params = defaultData
    } else {
      params = {
        page: campaignTableParams.campaignPage,
        itemsPerPage: campaignTableParams.campaignItemsPerPage,
        tableFilterValue: campaignTableParams.campaignTableFilterValue,
      }
    }

    try {
      const response = await findPatriotAddictCampaignData(params)
      setCampaignItems(response.data.data)
      setCampaignPages(response.data.totalPages)
      setCampaignLoading(false)
    } catch (error) {
      setCampaignLoading(false)
    }
  }

  const loadContactData = async (defaultData) => {
    setContactLoading(true)
    let params
    if (defaultData) {
      params = defaultData
    } else {
      params = {
        page: contactTableParams.contactPage,
        itemsPerPage: contactTableParams.contactItemsPerPage,
        tableFilterValue: contactTableParams.contactTableFilterValue,
      }
    }

    try {
      const response = await findContactData(params, "PATRIOT_ADDICT")
      setContactItems(response.data.data)
      setContactPages(response.data.totalPages)
      setContactLoading(false)
    } catch (error) {
      setContactLoading(false)
    }
  }

  const loadSegmentSettings = () => {
    const getSettings = async () => {
      try {
        const response = await findSettingsByType(SETTING_CAMPAIGN_SEGMENT_LIST)
        createSegmentOptions(response.data.data.properties.segment_list)
      } catch (error) {
        setNotFound(true)
        notify('Not found any settings data', 'e')
      }
    }
    getSettings()
  }

  const createSegmentOptions = (segments) => {
    const options = Object.entries(segments).map(([key, val]) => {
      return { value: key, label: val }
    })
    setSegments(options)
  }

  const refreshListData = () => {
    let defaultParams = {
      page: 1,
      itemsPerPage: 10,
      tableFilterValue: '',
    }
    setCampaignTableFilterValue('')
    setContactTableFilterValue('')
    loadCampaignData(defaultParams)
    loadContactData(defaultParams)
  }

  const onClickUploadData = () => {
    if (selectedFiles) {
      upload()
    } else {
      notify('Please select file!', 'w')
    }
  }

  const onClickScheduleCampaign = () => {
    let campaignNow = {
      sendNow: true,
      campaign: {
        name: data.campaignName,
        shopName: data.shopName,
        segment: selected.segmentName.value,
        message: data.smsMessage,
        replyStopCheck: data.isReplyStopCheck,
        productUrl: data.productUrl,
        scheduleTime: scheduleDate,
      },
    }

    if (validation()) handleCreateCampaign(campaignNow)
  }

  const onClickSendCampaignNow = () => {
    let campaignNow = {
      sendNow: true,
      campaign: {
        name: data.campaignName,
        shopName: data.shopName,
        segment: selected.segmentName.value,
        message: data.smsMessage,
        replyStopCheck: data.isReplyStopCheck,
        productUrl: data.productUrl,
      },
    }

    if (validation()) handleCreateCampaign(campaignNow)
  }

  const handleCreateCampaign = async (data) => {
    try {
      const res = await createPatriotAddictCampaign(data)
      notify(res.data.message, 's')
      clearError()
      hide(MODAL_CREATE_CAMPAIGN)
    } catch (error) {
      setNotFound(true)
      clearError()
      hide(MODAL_CREATE_CAMPAIGN)
      notify(
        'Cannot creating campaign at the moment! Please contact with system administrator!',
        'e',
      )
    }
  }

  const upload = async () => {
    let currentFile = selectedFiles[0]
    setCurrentFile(currentFile)

    setLoading(true)
    try {
      const res = await uploadPatriotAddictContact(currentFile)
      clearError()
      setLoading(false)
      hide(MODAL_UPLOAD_PHONE)
      notify(res.data.message, 's')
    } catch (error) {
      hide(MODAL_UPLOAD_PHONE)
      clearError()
      setLoading(false)
      notify('Could not upload the file!', 'e')
      setCurrentFile(undefined)
    }
    setCurrentFile(undefined)
  }

  const handleFindMemberBySegment = async (segment) => {
    try {
      const res = await findPatriotAddictMemberBySegment(segment)
      setSegmentMemberInfo(res.data.data)
    } catch (error) {
      clearError()
      notify('Could not find a segment member!', 'e')
    }
  }

  const onChangeSelect = (evt, selectType) => {
    if (selectType === 'file') {
      selectFile(evt)
    } else if (selectType === 'campaignName') {
      setData({
        ...data,
        campaignName: evt.target.value,
      })
    } else if (selectType === 'segmentName') {
      setSelected({
        ...selected,
        segmentName: evt,
      })
      handleFindMemberBySegment(evt.value)
    } else if (selectType === 'shopName') {
      setData({
        ...data,
        shopName: evt.target.value,
      })
    } else if (selectType === 'productUrl') {
      setData({
        ...data,
        productUrl: evt.target.value,
      })
    } else if (selectType === 'smsMessage') {
      setData({
        ...data,
        smsMessage: evt.target.value,
      })
    } else if (selectType === 'reviewMessage') {
      setData({
        ...data,
        reviewMessage: evt.target.value,
      })
    } else if (selectType === 'replyStopCheck') {
      setData({
        ...data,
        isReplyStopCheck: evt.target.checked,
      })
    }

    // Sms reflection will be implemented here
    reflectCampaignChangesToReviewMessage(evt, selectType)
  }

  const reflectCampaignChangesToReviewMessage = (evt, selectType) => {
    if (selectType === 'replyStopCheck') {
      let changedReviewMessage = defaultSmsMessage
      if (evt.target.checked) {
        changedReviewMessage = defaultSmsMessage + ' Reply STOP to stop'
      } else {
        changedReviewMessage = defaultSmsMessage
      }
      if (data.shopName) {
        changedReviewMessage = changedReviewMessage.replace('{shopName}', data.shopName)
      }
      if (data.productUrl) {
        changedReviewMessage = changedReviewMessage.replace(
          '{productUrl}',
          'https://mtpdls.com/xxxxx',
        )
      }

      setData({
        ...data,
        reviewMessage: changedReviewMessage,
        isReplyStopCheck: evt.target.checked,
      })
    } else if (selectType === 'shopName') {
      let changedReviewMessage = defaultSmsMessage
      if (evt.target.value) {
        changedReviewMessage = changedReviewMessage.replace('{shopName}', evt.target.value)
        if (data.isReplyStopCheck) {
          changedReviewMessage = changedReviewMessage + ' Reply STOP to stop'
        }
        if (data.productUrl) {
          changedReviewMessage = changedReviewMessage.replace(
            '{productUrl}',
            'https://mtpdls.com/xxxxx',
          )
        }
      }
      setData({
        ...data,
        reviewMessage: changedReviewMessage,
        shopName: evt.target.value,
      })
    } else if (selectType === 'productUrl') {
      let changedReviewMessage = defaultSmsMessage
      if (evt.target.value) {
        changedReviewMessage = changedReviewMessage.replace(
          '{productUrl}',
          'https://mtpdls.com/xxxxx',
        )
        if (data.isReplyStopCheck) {
          changedReviewMessage = changedReviewMessage + ' Reply STOP to stop'
        }
        if (data.shopName) {
          changedReviewMessage = changedReviewMessage.replace('{shopName}', data.shopName)
        }
      }
      setData({
        ...data,
        reviewMessage: changedReviewMessage,
        productUrl: evt.target.value,
      })
    } else if (selectType === 'smsMessage') {
      let changedReviewMessage = evt.target.value
      if (data.isReplyStopCheck) {
        changedReviewMessage = changedReviewMessage + ' Reply STOP to stop'
      }
      if (data.shopName) {
        changedReviewMessage = changedReviewMessage.replace('{shopName}', data.shopName)
      }
      if (data.productUrl) {
        changedReviewMessage = changedReviewMessage.replace(
          '{productUrl}',
          'https://mtpdls.com/xxxxx',
        )
      }
      setData({
        ...data,
        reviewMessage: changedReviewMessage,
        smsMessage: evt.target.value,
      })
    }
  }

  const selectFile = (evt) => {
    setSelectedFiles(evt.target.files)
  }

  const handleDeleteCampaign = () => {}

  // Validation
  const validation = () => {
    let validation = false
    if (isShow[MODAL_CREATE_CAMPAIGN]) {
      if (
        data.campaignName != null &&
        data.shopName != null &&
        data.productUrl != null &&
        selected.segmentName.value != null
      ) {
        validation = true
      } else if (data.campaignName === null) {
        setInputError({
          campaignNameError: 'Campaign Name field must be filled!',
        })
      } else if (data.shopName === null) {
        setInputError({
          shopNameError: 'Shop Name field must be filled!',
        })
      } else if (data.productUrl === null) {
        setInputError({
          productUrlError: 'Product URL field must be filled!',
        })
      } else if (selected.segmentName.value === null) {
        notify('Segment field must be filled!', 'e')
      }
    }

    return validation
  }

  const handleUnsubscribeContact = async (item) => {
    setLoading(true)
    try {
      const res = await unsubscribePatriotAddictContact(item.id)
      notify(res.data.message, 's')
      refreshListData()
    } catch (error) {
      setLoading(false)
      notify('While unsubscribing got an error! Please get in touch with admin!!', 's')
    }
  }

  // MODAL OPERATIONS
  const show = (modalType) => setIsShow(() => ({ ...isShow, [modalType]: true }))
  const hide = (modalType) => setIsShow(() => ({ ...isShow, [modalType]: false }))

  const openModal = (modalType) => {
    if (modalType === MODAL_UPLOAD_PHONE) {
      setCurrentFile(undefined)
    } else if (modalType === MODAL_CREATE_CAMPAIGN) {
      setData({
        campaignName: null,
        shopName: null,
        productUrl: null,
        smsMessage: defaultSmsMessage,
        reviewMessage: defaultSmsMessage,
        isReplyStopCheck: false,
      })
    }
    clearError()
    show(modalType)
  }

  const closeModal = (modalType) => {
    if (modalType === MODAL_UPLOAD_PHONE) {
      setCurrentFile(undefined)
    } else if (modalType === MODAL_CREATE_CAMPAIGN) {
      setSelected({
        ...selected,
        segmentName: {
          value: null,
          label: null,
        },
      })
      setSegmentMemberInfo('')
      setData({
        campaignName: null,
        shopName: null,
        productUrl: null,
        smsMessage: '',
        reviewMessage: '',
        isReplyStopCheck: false,
      })
    }

    clearError()
    hide(modalType)
  }

  const clearError = () => {
    if (isShow[MODAL_UPLOAD_PHONE]) {
      setCurrentFile(undefined)
    } else if (isShow[MODAL_CREATE_CAMPAIGN]) {
      setInputError({})
    }
  }

  const campaignFields = [
    { key: 'name', _style: { width: '20%' } },
    { key: 'segment', _style: { width: '25%' } },
    { key: 'shopName', _style: { width: '15%' } },
    //{ key: 'earned', _style: { width: '10%' } },
    { key: 'sentInfo', label: 'Sent', _style: { width: '5%' } },
    { key: 'clickedInfo', label: 'Clicked', _style: { width: '5%' } },
    { key: 'ctrInfo', label: 'CTR', _style: { width: '5%' } },
    { key: 'createdDate', _style: { width: '10%' } },
    { key: 'scheduleTime', _style: { width: '10%' } },
    { key: 'status', _style: { width: '10%' } },
  ]

  const contactFields = [
    { key: 'firstName', _style: { width: '20%' } },
    { key: 'lastName', _style: { width: '20%' } },
    { key: 'phone', _style: { width: '20%' } },
    { key: 'subscribed', _style: { width: '10%' } },
    { key: 'sentSmsCount', label: 'SMS Count', _style: { width: '10%' } },
    { key: 'clicker', _style: { width: '10%' } },
    {
      key: 'event',
      label: 'Unsubscribe',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ]

  const getBadge = (status) => {
    switch (status) {
      case 'in_progress':
        return 'success'
      case 'not_started':
        return 'secondary'
      case 'completed':
        return 'primary'
      case 'scheduled':
        return 'warning'
      default:
        return 'primary'
    }
  }

  const getBadgeText = (status) => {
    switch (status) {
      case 'in_progress':
        return 'IN PROGRESS'
      case 'not_started':
        return 'NOT STARTED'
      case 'scheduled':
        return 'SCHEDULED'
      case 'completed':
        return 'COMPLETED'
      case 'paused':
        return 'PAUSED'
      default:
        return 'UNDEFINED'
    }
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  return (
    <div className="container ml-auto" style={{ padding: '20px' }}>
      <CCard>
        <h3>PatriotAddict Account</h3>
        <CCardHeader>
          <select
            className="btn btn-outline-primary btn-sm"
            value={filterRange}
            onChange={(evt) => {
              loadSmsDashboardData(evt.target.value)
            }}
          >
            <option value="">Select Filter</option>
            <option value="TODAY">Today</option>
            <option value="YESTERDAY">Yesterday</option>
            <option value="PAST_WEEK">Past Week</option>
            <option value="PAST_2_WEEKS">Past 2 Weeks</option>
            <option value="THIS_MONTH">This Month</option>
            <option value="PAST_30_DAYS">Past 30 Days</option>
            <option value="LAST_MONTH">Last Month</option>
          </select>
        </CCardHeader>
        <CCardBody>
          <SmsKPI data={smsDashboard} status={loading}></SmsKPI>
        </CCardBody>
        <CCardHeader>
          <div className="row">
            <div className="col-sm text-left">
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={refreshListData}
              >
                Refresh List
              </button>
            </div>
            <div className="col-sm text-right">
              <button
                type="button"
                className="btn btn-info btn-sm mr-2"
                onClick={() => openModal(MODAL_UPLOAD_PHONE)}
              >
                Upload Phone Numbers
              </button>
              <button
                type="button"
                className="btn btn-info btn-sm mr-2"
                onClick={() => openModal(MODAL_CREATE_CAMPAIGN)}
              >
                Create Campaign
              </button>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CTabs activeTab="campaigns">
            <CNav variant="tabs">
              <CNavItem>
                <CNavLink data-tab="campaigns">Campaigns</CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink data-tab="contacts">Contacts</CNavLink>
              </CNavItem>
            </CNav>
            <CTabContent>
              <CTabPane data-tab="campaigns">
                <CDataTable
                  size="sm"
                  items={campaignItems}
                  fields={campaignFields}
                  loading={campaignLoading}
                  hover
                  cleaner
                  tableFilter={{ external: true }}
                  tableFilterValue={campaignTableFilterValue}
                  onTableFilterChange={setCampaignTableFilterValue}
                  sorter
                  itemsPerPageSelect={{ external: true }}
                  itemsPerPage={campaignItemsPerPage}
                  onPaginationChange={setCampaignItemsPerPage}
                  scopedSlots={{
                    createdDate: (item) => (
                      <td className="py-2">
                        {format(new Date(item.createdDate), 'yyyy/MM/dd kk:mm')}
                      </td>
                    ),
                    scheduleTime: (item) => (
                      <td className="py-2">
                        {item.scheduleTime ?
                          format(new Date(item.scheduleTime), 'yyyy/MM/dd kk:mm') : 'N/A'}
                      </td>
                    ),
                    sentInfo: (item) => (
                      <td>{item.sentInfo ? formatNumber(item.sentInfo) : 'N/A'}</td>
                    ),
                    clickedInfo: (item) => (
                      <td>{item.clickedInfo ? formatNumber(item.clickedInfo) : 'N/A'}</td>
                    ),
                    ctrInfo: (item) => <td>{item.ctrInfo ? item.ctrInfo + '%' : 'N/A'}</td>,
                    status: (item) => (
                      <td>
                        <CBadge color={getBadge(item.status)}>{getBadgeText(item.status)}</CBadge>
                      </td>
                    ),
                  }}
                />
                <CPagination
                  size="sm"
                  pages={campaignPages}
                  activePage={campaignPage}
                  onActivePageChange={setCampaignPage}
                  className={campaignPages < 2 ? 'd-none' : ''}
                />
              </CTabPane>

              <CTabPane data-tab="contacts">
                <CDataTable
                  size="sm"
                  items={contactItems}
                  fields={contactFields}
                  loading={contactLoading}
                  hover
                  cleaner
                  tableFilter={{ external: true }}
                  tableFilterValue={contactTableFilterValue}
                  onTableFilterChange={setContactTableFilterValue}
                  sorter
                  itemsPerPageSelect={{ external: true }}
                  itemsPerPage={contactItemsPerPage}
                  onPaginationChange={setContactItemsPerPage}
                  scopedSlots={{
                    event: (item) => (
                      <td className="py-2 text-center">
                        <CButton
                          disabled={!item.subscribed}
                          variant="outline"
                          shape="square"
                          color="danger"
                          size="sm"
                          onClick={() => {
                            handleUnsubscribeContact(item)
                          }}
                        >
                          <CIcon size="lg" name="cil-x-circle" />
                        </CButton>
                      </td>
                    ),
                  }}
                />
                <CPagination
                  size="sm"
                  pages={contactPages}
                  activePage={contactPage}
                  onActivePageChange={setContactPage}
                  className={contactPages < 2 ? 'd-none' : ''}
                />
              </CTabPane>
            </CTabContent>
          </CTabs>
        </CCardBody>
      </CCard>

      <CModal onClosed={() => closeModal(MODAL_UPLOAD_PHONE)} show={isShow[MODAL_UPLOAD_PHONE]}>
        <CModalHeader closeButton>Upload Contact Number</CModalHeader>
        <CModalBody>
          <div className="container">
            <div>
              <CAlert color="warning" className="d-flex align-items-center">
                <svg className="flex-shrink-0 me-2" width="24" height="24" viewBox="0 0 512 512">
                  <rect
                    width="32"
                    height="176"
                    x="240"
                    y="176"
                    fill="var(--ci-primary-color, currentColor)"
                    className="ci-primary"
                  ></rect>
                  <rect
                    width="32"
                    height="32"
                    x="240"
                    y="384"
                    fill="var(--ci-primary-color, currentColor)"
                    className="ci-primary"
                  ></rect>
                  <path
                    fill="var(--ci-primary-color, currentColor)"
                    d="M274.014,16H237.986L16,445.174V496H496V445.174ZM464,464H48V452.959L256,50.826,464,452.959Z"
                    className="ci-primary"
                  ></path>
                </svg>
                <div>
                  The order of the data in the file should be like: [Phone Number, Firstname,
                  Lastname].
                </div>
              </CAlert>
            </div>
            <div>
              <label className="btn btn-default">
                <input type="file" onChange={(evt) => onChangeSelect(evt, 'file')} />
              </label>
            </div>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="success" onClick={onClickUploadData}>
            Upload
          </CButton>
        </CModalFooter>
      </CModal>

      <CModal
        size="lg"
        onClosed={() => closeModal(MODAL_CREATE_CAMPAIGN)}
        show={isShow[MODAL_CREATE_CAMPAIGN]}
      >
        <CModalHeader closeButton>Create Campaign</CModalHeader>
        <CModalBody>
          <div className="container">
            <CForm className="row g-3">
              <CCol xs={12}>
                <ModalInput
                  name="campaignName"
                  label="Campaign Name"
                  onChange={(evt) => onChangeSelect(evt, 'campaignName')}
                  defaultValue={data.campaignName}
                  error={inputError.campaignNameError}
                ></ModalInput>
              </CCol>
            </CForm>
            <CForm className="row g-3">
              <CCol xs={6}>
                <ModalSelect
                  name="segmentName"
                  label="Segment"
                  placeHolder="Choose Segment.."
                  onChange={(evt) => onChangeSelect(evt, 'segmentName')}
                  options={segments.map((item) => {
                    return { value: item.value, label: item.label }
                  })}
                  value={selected.segmentName}
                />
              </CCol>
              <CCol xs={6}>
                <CLabel>Member Count</CLabel>
                <CInput disabled value={segmentMemberInfo} />
              </CCol>
            </CForm>
            <CForm className="row g-3">
              <CCol xs={12}>
                <ModalInput
                  name="shopName"
                  label="Shop Name"
                  onChange={(evt) => onChangeSelect(evt, 'shopName')}
                  defaultValue={data.shopName}
                  error={inputError.shopNameError}
                ></ModalInput>
              </CCol>
            </CForm>
            <CForm className="row g-3">
              <CCol xs={12}>
                <ModalInput
                  name="productUrl"
                  label="Product URL"
                  onChange={(evt) => onChangeSelect(evt, 'productUrl')}
                  defaultValue={data.productUrl}
                  error={inputError.productUrlError}
                ></ModalInput>
              </CCol>
            </CForm>
            <CForm className="row g-3 mb-4">
              <CCol xs={6}>
                <CLabel>Select Schedule Time</CLabel>
                <DatePicker
                  wrapperClassName="datePicker"
                  showTimeSelect
                  selected={scheduleDate}
                  timeIntervals={15}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  disabledKeyboardNavigation
                  onChange={(date) => setScheduleDate(date)}
                />
              </CCol>
            </CForm>
            <ReviewMessage
              name="smsMessage"
              label="Message"
              disabled={false}
              handleChange={(evt) => onChangeSelect(evt, 'smsMessage')}
              rows={4}
              inputText={data.smsMessage}
            ></ReviewMessage>
            <CForm className="row g-3">
              <CCol xs={6}>
                <CSwitch
                  className="mt-1 ml-1"
                  checked={data.isReplyStopCheck}
                  color={data.isReplyStopCheck ? 'success' : 'danger'}
                  variant="3d"
                  shape="square"
                  labelOn={'\u2713'}
                  labelOff={'\u2715'}
                  onChange={(evt) => onChangeSelect(evt, 'replyStopCheck')}
                />
                <CLabel>Add "Reply STOP to stop."</CLabel>
              </CCol>
            </CForm>
            <SmsMessage
              name="reviewMessage"
              label="Review Message"
              handleChange={(evt) => onChangeSelect(evt, 'reviewMessage')}
              limit={153}
              rows={4}
              inputText={data.reviewMessage}
              disabled={true}
            ></SmsMessage>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={onClickScheduleCampaign}>
            Schedule Campaign
          </CButton>
          <CButton color="success" onClick={onClickSendCampaignNow}>
            Send Campaign Now
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  )
}

export default SmsCampaignPatriotAddictPage
