// v1.0 API PATH CONSTANTS

// HOST Defining
// DEV
export const BACKEND_HOST_DEV = 'http://localhost:8080/'
// TEST
export const BACKEND_HOST_TEST = 'http://10.120.10.31:8080/'
// PROD
export const BACKEND_HOST_PROD =
  'http://prod-tacticaltoday-app-lb-907271523.us-east-2.elb.amazonaws.com/'

// PROD
//export const BACKEND_HOST = "http://ec2-3-132-191-173.us-east-2.compute.amazonaws.com:8082/";

// Auth Api
export const API_SIGNIN = '/api/1.0/auth/signin'
export const API_SIGNUP = '/api/1.0/auth/signup'

// Subscription Api
export const API_SUBSCRPTIONS = '/api/1.0/subscriptions'
export const API_SUBSCRPTIONS_PAGE_FILTER = '/api/1.0/subscriptions/filter'
export const API_SUBSCRPTIONS_PAGE_FILTER_ADVANCED = '/api/1.0/subscriptions/filter/advanced'
export const API_UPLOAD_SUBSCRPTIONS = '/api/1.0/subscriptions/upload'
export const API_FIND_BY_ID = '/api/1.0/subscriptions'
export const API_SUBSCRIPTION_FILTER = '/api/1.0/subscriptions/dateFilter'
export const API_SUBSCRPTION_SHIPMENT_FILTER = '/api/1.0/subscriptions/shipment'
export const API_SUBSCRIPTION_FILTER_REPORTS = '/api/1.0/subscriptions/dateFilterReports'
export const API_SUBSCRIPTION_TRANSACTIONS =
  '/api/1.0/subscriptions/findTransactionsBySubscriptionId'

// Shipstation Api
export const API_SHIPSTATION = '/api/1.0/shipstation'
export const API_SHIPSTATION_CREATE_MULTIPLE_ORDERS = '/api/1.0/shipstation/createMultipleOrders'

// Stripe api

// Customer api
export const API_CUSTOMERS = '/api/1.0/customer/paging'
export const API_CUSTOMERS_FILTER = '/api/1.0/customer/filter'
export const API_CUSTOMERS_FILTER_ADVANCED = '/api/1.0/customer/filter/advanced'
export const API_FIND_CUSTOMER = '/api/1.0/customer'
export const API_CUSTOMER_UPDATE = '/api/1.0/customer/update'
export const API_DELETE_CUSTOMER = '/api/1.0/customer'
export const API_FIND_CUSTOMER_MODIFICATIONS = '/api/1.0/customer/modifications'
export const API_CUSTOMER_UNSUBSCRIBE_MAIL = '/api/1.0/customer/unsubscribe'

// Product Api
export const API_PRODUCT_CREATE = '/api/1.0/product'
export const API_PRODUCT_UPDATE = '/api/1.0/product'
export const API_PRODUCT_UPDATE_LABEL = '/api/1.0/product/labelUpdate'
export const API_PRODUCT_DELETE = '/api/1.0/product'
export const API_PRODUCTS = '/api/1.0/product'
export const API_PRODUCTS_UPLOAD = '/api/1.0/product/upload'
export const API_PRODUCTS_DOWNLOAD = '/api/1.0/product/download'
export const API_PRODUCT_MAPPING = '/api/1.0/product/mapping'
export const API_PRODUCT_LABEL = '/api/1.0/product/label'

// Funnel Api
export const API_FUNNEL_FIND_ALL = '/api/1.0/funnel'
export const API_FUNNEL_UPDATE = '/api/1.0/funnel'

// Sku Api
export const API_SKU_CREATE = '/api/1.0/sku'
export const API_SKU_FIND_ALL = '/api/1.0/sku'
export const API_SKU_UPDATE = '/api/1.0/sku'
export const API_SKU_DELETE = '/api/1.0/sku'

// Scheduler Api
export const API_SCHEDULER_JOB = '/api/1.0/scheduler/job'

// Job Api
export const API_JOB_FIND_JOBS = '/api/1.0/job'
export const API_JOB_FIND_BY_ID = '/api/1.0/job'

// Job History Api
export const API_JOB_HISTORY_FIND_JOB_HISTORIES = '/api/1.0/job_history'
export const API_JOB_HISTORY_FIND_BY_ID = '/api/1.0/job_history'
export const API_JOB_HISTORY_DELETE = '/api/1.0/job_history'

// Check Subscriptions Api
export const API_CHECK_SUBSCRIPTIONS = '/api/1.0/check_subscriptions'
export const API_CHECK_SUBSCRIPTIONS_PAGE_FILTER = '/api/1.0/check_subscriptions/filter'
export const API_CHECK_SUBSCRIPTION_UPDATE_VIEW = '/api/1.0/check_subscriptions'

// Document Api
export const API_DOCUMENT_UPLOAD = '/api/1.0/document'

// Report Api
export const API_REPORT_DOWNLOAD_REPORTS = '/api/1.0/report/download'
export const API_REPORT_UPLOAD_REPORTS = '/api/1.0/report/upload'
export const API_SEND_REPORTS_VIA_MAIL = '/api/1.0/report/sendReportsViaMail'
export const API_REPORT_MAINTENANCE = '/api/1.0/report/maintenance'
export const API_REPORT_MAINTENANCE_LIST = '/api/1.0/report/updateValues'

// Domain Mapping Api
export const API_MAPPING = '/api/1.0/mapping'
export const API_MAPPING_UPDATE = '/api/1.0/mapping'

// Sendlane Api
export const API_SENDLANE_DOMAIN_FIND_ALL = '/api/1.0/sendlane'
export const API_SENDLANE_DOMAIN_SAVE = '/api/1.0/sendlane'
export const API_SENDLANE_FUNNEL_SAVE = '/api/1.0/sendlane/funnel'

// Klaviyo Api
export const API_KLAVIYO_LIST_FIND_ALL = '/api/1.0/klaviyo'

// Campaigner Api
export const API_CAMPAIGNER_LIST_FIND_ALL = '/api/1.0/campaigner'

// Klaviyo Api
export const API_ACTIVE_CAMPAIGN_LIST_FIND_ALL = '/api/1.0/activecampaign/list'

// Klaviyo Api
export const API_MAROPOST_LIST_FIND_ALL = '/api/1.0/maropost/lists'

// Dashboard Api
export const API_DASHBOARD = '/api/1.0/dashboard'

// Bucket API
export const API_BUCKET_UPLOAD = '/api/1.0/bucket/upload'
export const API_BUCKET_DELETE_ALL_BUCKETS = '/api/1.0/bucket/all'
export const API_BUCKET_JOB_OPERATION = '/api/1.0/bucket/job'

// Backup API
export const API_BACKUP_UPLOAD = '/api/1.0/backup/upload'
export const API_BACKUP_FIND_ALL = '/api/1.0/backup/filter'
export const API_BACKUP_LABEL = '/api/1.0/backup/label'

// Email Block API
export const API_EMAIL_BLOCK_FIND_ALL = '/api/1.0/email_block/filter'
export const API_EMAIL_BLOCK_DELETE_BY_ID = '/api/1.0/email_block'
export const API_EMAIL_BLOCK_INSERT = '/api/1.0/email_block'
export const API_EMAIL_BLOCK_UPLOAD = '/api/1.0/email_block/upload'

// Settings API
export const API_SETTINGS_FIND_ALL = '/api/1.0/settings'
export const API_SETTINGS_UPDATE = '/api/1.0/settings'
export const API_SETTINGS_FIND_BY_TYPE = '/api/1.0/settings/type'
export const API_SETTINGS_BUCKET_FIND_BY_KEY = '/api/1.0/settings/key'

// Entremax Campaign API
export const API_CAMPAIGN = '/api/1.0/sms_campaign'
export const API_CAMPAIGN_IMAGE = '/api/1.0/aws/upload'

// Contact API
export const API_CAMPAIGN_CONTACT = '/api/1.0/sms_contact'

// Failed Transactions
export const API_FAILED_TRANSACTION_ACTION_UPDATE = '/api/1.0/failed_transaction'
export const API_FAILED_TRANSACTION_FILTER = '/api/1.0/failed_transaction/filter'
export const API_FAILED_TRANSACTION_ACTION_COUNT = '/api/1.0/failed_transaction/action_count'

// Cancel Membership
export const API_CANCEL_MEMBERSHIP_FILTER = '/api/1.0/cancel-membership/filter'
export const API_CANCEL_MEMBERSHIP_STATUS_COUNT = '/api/1.0/cancel-membership/status-count'
export const API_CANCEL_MEMBERSHIP_STATUS_UPDATE = '/api/1.0/cancel-membership'
