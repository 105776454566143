import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '@coreui/coreui/dist/css/coreui.css'
import {
  findCancelMembershipData,
  findStatusCountsData,
  updateCancelMembershipStatus,
} from '../api/apiCalls'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCol,
  CDataTable,
  CNav,
  CNavItem,
  CNavLink,
  CPagination,
  CRow,
  CSelect,
  CTabContent,
  CTabPane,
  CTabs,
  CWidgetDropdown,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

toast.configure()
const CancelMembershipPage = () => {
  const [notFound, setNotFound] = useState(false)

  // Table Integration
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(100)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [tableFilterValue, setTableFilterValue] = useState('')
  const [status, setStatus] = useState('')
  const [reason, setReason] = useState('')
  const [counts, setCounts] = useState({})

  const params = {
    page,
    itemsPerPage,
    tableFilterValue,
    status,
    reason
  }

  useEffect(() => {
    setNotFound(false)
  }, [])

  useEffect(() => {
    loadCancelMembershipData()
  }, [page, itemsPerPage, tableFilterValue, status])

  const loadCancelMembershipData = async () => {
    setLoading(true)
    try {
      const response = await findCancelMembershipData(params)
      loadStatusCountsData()
      setItems(response.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const updateStatus = async (id, status) => {
    setLoading(true)
    try {
      await updateCancelMembershipStatus(id, status)
      loadCancelMembershipData()
      notify('Cancel Membership status has been changed!', 's')
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const loadStatusCountsData = async () => {
    try {
      const response = await findStatusCountsData()
      setCounts(response.data.data)
    } catch (error) {
      setLoading(false)
    }
  }

  const fields = [
    { key: 'subscriptionId', _style: { width: '5%' } },
    { key: 'paymentGateway', _style: { width: '10%' } },
    { key: 'email', _style: { width: '10%' } },
    { key: 'phone', _style: { width: '5%' } },
    { key: 'description', label: 'Reason Description', _style: { width: '30%' } },
    {
      key: 'status',
      label: 'Record Status',
      _style: { width: '15%' },
      sorter: false,
      filter: false,
    },
  ]

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  return (
    <div className="ml-auto" style={{ padding: '20px' }}>
      <div>
        <CRow>
          <CCol sm="1">
            <CWidgetDropdown
              color="gradient-primary"
              header={counts.active ? counts.active : '0'}
              text="Active"
              footerSlot={<></>}
            >
              <CIcon name="cil-people" height="30" />
            </CWidgetDropdown>
          </CCol>
          <CCol sm="2" lg="2">
            <CWidgetDropdown
              color="gradient-warning"
              header={counts.cancellationInProgress ? counts.cancellationInProgress : '0'}
              text="Cancellation In Progress"
              footerSlot={<></>}
            >
              <CIcon name="cil-phone" height="30" />
            </CWidgetDropdown>
          </CCol>
          <CCol sm="2">
            <CWidgetDropdown
              color="gradient-success"
              header={counts.customerReached ? counts.customerReached : '0'}
              text="Customer Reached"
              footerSlot={<></>}
            >
              <CIcon name="cil-check" height="30" />
            </CWidgetDropdown>
          </CCol>
          <CCol sm="1" lg="2">
            <CWidgetDropdown
              color="gradient-info"
              header={counts.needsCallBack ? counts.needsCallBack : '0'}
              text="Needs Call Back"
              footerSlot={<></>}
            >
              <CIcon name="cil-phone" height="30" />
            </CWidgetDropdown>
          </CCol>
          <CCol sm="2" lg="2">
            <CWidgetDropdown
              color="gradient-warning"
              header={counts.waitingForCustomerReach ? counts.waitingForCustomerReach : '0'}
              text="Waiting For Customer Reach"
              footerSlot={<></>}
            >
              <CIcon name="cil-user-unfollow" height="30" />
            </CWidgetDropdown>
          </CCol>
          <CCol sm="1">
            <CWidgetDropdown
              color="gradient-success"
              header={counts.noNeedToCall ? counts.noNeedToCall : '0'}
              text="No Need Call"
              footerSlot={<></>}
            >
              <CIcon name="cil-money" height="30" />
            </CWidgetDropdown>
          </CCol>
          <CCol sm="2">
            <CWidgetDropdown
              color="gradient-danger"
              header={counts.subscriptionCanceled ? counts.subscriptionCanceled : '0'}
              text="Subscription Cancelled"
              footerSlot={<></>}
            >
              <CIcon name="cil-x-circle" height="30" />
            </CWidgetDropdown>
          </CCol>
        </CRow>
      </div>
      <div>
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol sm="2">
                <CCard style={{ width: '18rem' }}>
                  <CCardBody>
                    <CCardTitle>Status</CCardTitle>
                    <CCardText>Select to filter status</CCardText>
                    <select
                      label="Status:"
                      className="btn btn-outline-primary btn-sm"
                      value={params.status}
                      onChange={(evt) => {
                        setStatus(evt.target.value)
                        setPage(1)
                      }}
                    >
                      <option value="">No Action Selected</option>
                      <option value="CANCELLATION_IN_PROGRESS">Cancellation In Progress</option>
                      <option value="REACHED_CUSTOMER">Customer Reached</option>
                      <option value="NEEDS_CALL_BACK">Needs Call Back</option>
                      <option value="WAITING_FOR_CUSTOMER_REACH">Waiting for Customer Reach</option>
                      <option value="NO_NEED_TO_CALL">No Need to Call</option>
                      <option value="SUBSCRIPTION_CANCELED">Subscription Cancelled</option>
                    </select>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CTabs activeTab="cancel_membership">
              <CNav variant="tabs">
                <CNavItem>
                  <CNavLink data-tab="cancel_membership">Cancel Memberships</CNavLink>
                </CNavItem>
              </CNav>
              <CTabContent>
                <CTabPane data-tab="cancel_membership">
                  <CDataTable
                    size="sm"
                    items={items}
                    fields={fields}
                    loading={loading}
                    hover
                    cleaner
                    tableFilter={{ external: true }}
                    tableFilterValue={tableFilterValue}
                    onTableFilterChange={setTableFilterValue}
                    sorter
                    itemsPerPageSelect={{ external: true }}
                    itemsPerPage={itemsPerPage}
                    onPaginationChange={setItemsPerPage}
                    scopedSlots={{
                      createdDate: (row) => (
                        <td className="py-2">
                          {`${('0' + (new Date(row.createdDate).getUTCMonth() + 1)).slice(-2)}/${(
                            '0' + new Date(row.createdDate).getUTCDate()
                          ).slice(-2)}/${new Date(row.createdDate).getUTCFullYear()}`}
                        </td>
                      ),
                      status: (item) => (
                        <td className="py-2">
                          <CSelect
                            value={item.status}
                            onChange={(evt) => {
                              updateStatus(item.id, evt.target.value)
                            }}
                          >
                            <option value="CANCELLATION_IN_PROGRESS">
                              Cancellation In Progress
                            </option>
                            <option value="REACHED_CUSTOMER">Customer Reached</option>
                            <option value="NEEDS_CALL_BACK">Needs Call Back</option>
                            <option value="WAITING_FOR_CUSTOMER_REACH">
                              Waiting for Customer Reach
                            </option>
                            <option value="NO_NEED_TO_CALL">No Need to Call</option>
                            <option value="SUBSCRIPTION_CANCELED">Subscription Cancelled</option>
                          </CSelect>
                        </td>
                      ),
                      description: (item) => (
                        <td className="py-2">
                          {item.reason}: {item.description}
                        </td>
                      )
                    }}
                  />
                  <CPagination
                    size="sm"
                    pages={pages}
                    activePage={page}
                    onActivePageChange={setPage}
                    className={pages < 2 ? 'd-none' : ''}
                  />
                </CTabPane>
              </CTabContent>
            </CTabs>
          </CCardBody>
        </CCard>
      </div>
    </div>
  )
}

export default CancelMembershipPage
